import { createAction } from 'typesafe-actions';
import { SettingPanels } from 'widgets/Settings/types';
import { ACTIONS, IChatMessage } from './types';

export const addedMessageStore = createAction(ACTIONS.ADDED_MESSAGE_STORE)<IChatMessage>();

export const sendNewMessage = createAction(ACTIONS.SEND_NEW_MESSAGE)<string>();

export const setErrorMessage = createAction(ACTIONS.SET_ERROR_MESSAGE)<string | null>();

export const addNewMessageInput = createAction(ACTIONS.ADD_NEW_MESSAGE)<string | null>();

export const setIsBlockedChat = createAction(ACTIONS.SET_IS_BLOCKED_CHAT)<boolean>();

export const setIsSendButtonBlocked = createAction(ACTIONS.SET_IS_SEND_BUTTON_BLOCKED)<boolean>();

export const setLoadingSendMessage = createAction(ACTIONS.SET_LOADING_SEND_MESSAGE)<boolean>();

export const setIsChatInputFocused = createAction(ACTIONS.SET_IS_CHAT_INPUT_FOCUSED)<boolean>();
