import * as React from 'react';
import { memo, useState } from 'react';

import styled, { css } from 'styled-components';

import { Icon, IconNames } from 'components';

export interface IInput extends IInputStyle {
  value?: string;
  placeholder?: string;
  errorMessage?: string;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onClick: () => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  max?: number;
  hasError?: boolean;
}

export interface IInputStyle {
  color?: string;
  disabled?: boolean;
  $hasError?: boolean;
  hasWarning?: boolean;
  className?: string;
  hideInputValue?: boolean;
}

const InputComponent = React.forwardRef<HTMLInputElement, IInput>(
  (
    {
      value,
      name,
      errorMessage,
      onChange,
      onFocus,
      onBlur,
      onKeyDown,
      onClick,
      max = 150,
      hasError,
      hideInputValue,
      className,
      disabled,
      ...rest
    }: IInput,
    ref,
  ) => {
    return (
      <Wrapper className={className}>
        <InputContainer>
          <InputStyled
            value={value}
            name={name}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            maxLength={max}
            hideInputValue={hideInputValue}
            type={'text'}
            $hasError={Boolean(hasError)}
            ref={ref}
            disabled={disabled}
            onKeyDown={onKeyDown}
            {...rest}
          />

          <IconTouchableWrapper onClick={onClick}>
            <Icon name={disabled ? IconNames.BlackArrowSend : IconNames.ArrowSend} />
          </IconTouchableWrapper>
        </InputContainer>

        <InputMessage $show={Boolean(errorMessage)} $hasError={Boolean(hasError)}>
          {errorMessage}
        </InputMessage>
      </Wrapper>
    );
  },
);

export const Input = memo(InputComponent);

const Wrapper = styled.div`
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
`;

const InputLabel = styled.p<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  color: ${({ theme: { primaryTextColor } }) => primaryTextColor};
  background-color: transparent;
  margin: 0 0 8px 0;
  letter-spacing: 0.02em;
  transform: scale(1, 1.1);
`;

const InputMessage = styled.p<{ $show: boolean; $hasError: boolean }>`
  display: ${({ $show }) => ($show ? 'block' : 'none')};
  height: 100%;
  width: 100%;
  margin-top: 4px;
  color: ${({ $hasError, theme: { primaryTextColor, errorTextColor } }) =>
    $hasError ? errorTextColor : primaryTextColor};
`;

const InputStyled = styled.input<IInputStyle>`
  touch-action: manipulation;
  user-select: none;
  width: 100%;
  padding: 8rem 32rem 8rem 12rem;
  box-sizing: border-box;
  background-color: transparent !important;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ $hasError, theme: { borderColor, errorTextColor } }) =>
    $hasError ? errorTextColor : borderColor};
  border-radius: 2px;
  color: ${({ $hasError, theme: { primaryTextColor, errorTextColor } }) =>
    $hasError ? errorTextColor : primaryTextColor};
  color: #e7d5b9;
  font-size: 12rem;
  font-weight: 400;
  cursor: pointer;
  letter-spacing: 0.1em;
  outline: none;
  ${({ hideInputValue }) => {
    if (hideInputValue) {
      return css`
        -webkit-text-security: disc;
      `;
    }
  }}

  &:focus {
    border-color: ${({ $hasError, theme: { focusedBorderColor, errorTextColor } }) =>
      $hasError ? errorTextColor : focusedBorderColor};
  }

  &::placeholder {
    color: #e7d5b9;
    font-style: normal;
    font-weight: 400;
    font-size: 12rem;
  }

  &::-ms-input-placeholder {
    color: #e7d5b9;
    font-style: normal;
    font-weight: 400;
    font-size: 12rem;
  }

  ${({ disabled, theme: { borderColor, primaryTextColor } }) => {
    if (disabled) {
      return css`
        color: #434343;
        border: 1px solid #434343;
        cursor: disable;

        &::placeholder {
          color: #434343;
        }

        &::-ms-input-placeholder {
          color: #434343;
        }
      `;
    }
  }}
`;

const IconTouchableWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 40rem;
  height: 100%;
  cursor: pointer;
  // z-index: 10;

  svg {
    width: 16rem;
    height: 16rem;
  }
`;

const InputContainer = styled.div`
  position: relative;
`;

const InputDisabled = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #434343;
  background: linear-gradient(0deg, #121011 0%, #343434 100%);
`;
