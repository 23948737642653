import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'core';
import { IChatPanelState } from './types';

const chatPanelSelector = (state: IState) => state.chatPanelReducer;

export const chatSelector = createSelector(chatPanelSelector, (state: IChatPanelState) => ({
  messages: state.messages,
  newMessage: state.newMessage,
  messageError: state.messageError,
  isBlockedChat: state.isBlockedChat,
  isSendButtonBlocked: state.isSendButtonBlocked,
  lastSentMessages: state.lastSentMessages,
  loadingSendMessage: state.loadingSendMessage,
  isChatInputFocused: state.isChatInputFocused,
}));
