import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IChatPanelProps } from './types';
import { ChatPanelComponentResponsive } from './Responsive';
import { chatSelector } from 'core/widgets/ChatPanel';
import {
  sendNewMessage,
  addNewMessageInput,
  setIsChatInputFocused,
  setLoadingSendMessage,
  setIsSendButtonBlocked,
} from 'core/widgets/ChatPanel/actions';
import { messagesTest } from './messagesTest';
import { chatWebSocketService } from 'services/ChatWebSocketService';

export const ChatPanel = ({ onCloseClick }: IChatPanelProps) => {
  const dispatch = useDispatch();

  const {
    messages,
    newMessage,
    messageError,
    isBlockedChat,
    isSendButtonBlocked,
    loadingSendMessage,
    isChatInputFocused,
  } = useSelector(chatSelector);

  const onSendMessage = () => {
    if (!loadingSendMessage) {
      if (newMessage?.length) {
        dispatch(sendNewMessage(newMessage));
      }
    }
  };

  const handleMessageChange = (event: any) => {
    dispatch(addNewMessageInput(event.target.value));
  };

  const handleFocus = (focused: any) => {
    dispatch(setIsChatInputFocused(focused));
  };

  const isConnected = chatWebSocketService.isConnected();

  useEffect(() => {
    if (isConnected && !isBlockedChat) {
      dispatch(setLoadingSendMessage(false));
      dispatch(setIsSendButtonBlocked(false));
    }

    if (!isConnected) {
      dispatch(setLoadingSendMessage(false));
      dispatch(setIsSendButtonBlocked(true));
    }
  }, [dispatch, isBlockedChat, isConnected]);

  return (
    <>
      <ChatPanelComponentResponsive
        messages={messages}
        newMessage={newMessage}
        messageError={messageError}
        isBlockedChat={isBlockedChat || isSendButtonBlocked}
        isChatInputFocused={isChatInputFocused}
        onSendMessage={onSendMessage}
        handleMessageChange={handleMessageChange}
        onCloseClick={onCloseClick}
        handleFocus={handleFocus}
      />
    </>
  );
};
