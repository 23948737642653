import { call, delay, put, select } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import { ActionType } from 'typesafe-actions';

import { Notifications } from 'types';
import {
  addNewMessageInput,
  sendNewMessage,
  setIsSendButtonBlocked,
  setLoadingSendMessage,
} from 'core/widgets/ChatPanel/actions';
import { chatWebSocketService } from 'services/ChatWebSocketService';
import { chatSelector } from '../selectors';

export function* sendNewMessageChatSaga({ payload: message }: ActionType<typeof sendNewMessage>) {
  try {
    yield put(setLoadingSendMessage(true));

    const { type, data } = yield call(() =>
      chatWebSocketService.sendMessage({
        type: Notifications.SEND_CHAT_MESSAGE,
        data: { message },
      }),
    );

    if (data) {
      yield put(setLoadingSendMessage(true));
      yield put(setIsSendButtonBlocked(false));
      yield put(addNewMessageInput(null));
      yield put(setLoadingSendMessage(false));
    }

    if (type === 'ERROR' && data?.reason === 'ChatMessageLimit') {
      yield put(setIsSendButtonBlocked(true));
      yield call(() => handleSendMessage());
      yield put(setLoadingSendMessage(false));
    }
  } catch (error) {
    yield put(setLoadingSendMessage(false));
    Sentry.captureException({ error });
  }
}

function* handleSendMessage() {
  const { lastSentMessages } = yield select(chatSelector);

  const now = Date.now();
  const oneMinuteAgo = now - 60 * 1000;

  const messagesInLastMinute = lastSentMessages.filter(
    (timestamp: number) => timestamp >= oneMinuteAgo,
  );

  if (messagesInLastMinute.length < 10) {
    yield delay(20000);
    yield put(setIsSendButtonBlocked(false));
  }

  if (messagesInLastMinute.length >= 10) {
    const firstMessageTime = messagesInLastMinute[0];

    const timeUntilUnblock = 60 * 1000 - (now - firstMessageTime);

    yield delay(timeUntilUnblock);

    yield put(setIsSendButtonBlocked(false));
  }
}
